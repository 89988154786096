<template>
  <section class="section">
    <b-loading :is-full-page="false" v-model="loadingEpicDetails"></b-loading>

    <div v-if="selectedEpic">
      <div class="columns mb-6">
        <div class="column is-9">
          <h4 class="title is-4">{{ selectedEpic.name }}<br /><small>ID: {{ selectedEpic.id }}</small></h4>

          <h6 class="title is-6 mb-0">Description:</h6>
          <p>{{ selectedEpic.description }}</p>

          <h6 class="title is-6 mt-3 mb-0">Legend:</h6>
          <p>{{ selectedEpic.legend }}</p>
        </div>
      </div>
      <b-tabs type="is-boxed" expanded>
        <b-tab-item label="Details">
          <epic-form :epic="selectedEpic"></epic-form>
        </b-tab-item>
        <b-tab-item label="Feats">
          <b-collapse :open="false" class="mt-6">
            <template #trigger>
              <b-button
                label="Create New Feat"
                icon-left="plus"
                type="is-info" />
            </template>
            <feat-form :epic="selectedEpic"></feat-form>
          </b-collapse>
          <feat-list :epic="selectedEpic" :feats="selectedEpic.feats"></feat-list>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import FeatList from '../../components/Feats/FeatList.vue';
import EpicForm from '../../components/Feats/EpicForm.vue';
import FeatForm from '../../components/Feats/FeatForm.vue';

export default {
  name: 'EpicDetails',
  components: {
    FeatForm,
    EpicForm,
    FeatList,
  },
  props: {
    epic: String,
  },
  computed: {
    ...mapState('feats', ['loadingEpicDetails', 'selectedEpic']),
  },
  mounted() {
    this.loadEpic(this.epic);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadEpic(to.params.epic);
    next();
  },
  methods: {
    loadEpic(id) {
      this.$store.dispatch('feats/loadEpicDetails', id);
    },
  },
};
</script>

<style lang="scss">
.stat-list > div {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  .user-stat-icon {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
}

</style>
